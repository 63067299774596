<template>
  <v-text-field label="Team" outlined v-model="team"></v-text-field>
</template>

<script>
import Parcel from '../../services/parcel';
export default {
  props: {
    parcelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      team: ''
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const p = new Parcel({ id: this.parcelId });
      p.detail().then(data => {
        this.team = data?.team || '';
      });
    },
    save() {
      const p = new Parcel({ id: this.parcelId });
      p.fetch().then(() => {
        p.update({ team: this.team });
      });
    }
  }
};
</script>

<style>
</style>
